<template>
  <span>
    <template v-if="status == 'complete'">
      <font-awesome-icon :icon="['far','circle-check']" :style="{ color: 'var(--status-green)'}" />
      {{$t('milestone.status.complete')}}
    </template>
    <template v-if="status == 'late'">
      <font-awesome-icon :icon="['far','alarm-exclamation']" :style="{ color: 'var(--status-red)'}" />
      {{$t('milestone.status.late')}}
    </template>
    <template v-if="status == 'soon'">
      <font-awesome-icon :icon="['far','clock']" :style="{ color: 'var(--status-orange)'}" />
      {{$t('milestone.status.soon')}}
    </template>
    <template v-if="status == 'pending'">
      <font-awesome-icon :icon="['far', 'circle-minus']" :style="{ color: 'var(--status-blue)'}" />
      {{$t('milestone.status.pending')}}
    </template>
  </span>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'MilestoneStatusCellRenderer',
  data() {
    return {
      status: null,
    }
  },
  created() {
    this.status = this.params.value;
  },
  methods: {
  }
});
</script>
